
/* STORAGE STARTS */
@media screen and (min-width: 768px) {
  .storage-header {
    position: absolute;
    top: 40%;
    left: 10%;
    font-size: 44px !important;
    width: 35%;
    font-weight: 600;
    text-align: left;
  }

  .storage-title {
    padding: 20px 10px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 44px;
  }

  .storage-banner-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .storage-banner-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .storage-header {
    position: absolute;
    bottom: 10%;
    left: 10%;
    font-size: 14px !important;
    width: 50%;
    font-weight: 700;
    text-align: left;
  }

  .storage-title {
    padding: 40px 20px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 24px;
  }

  .storage-banner-container {
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    min-height: 300px;
  }

  .storage-banner-image {
    width: 100%;
    height: auto;
    transform: scale(2.2, 2.0);
    transition: transform 0.3s ease;
    transform-origin: top;
  }
}

.storage-sub {
  padding: 0px 20px;
  text-align: left;
  color: white;
  font-weight: 400;
  font-size: 18px;
}

.storage-card {
  background-color: #1C1C1C;
  border: 0px;
  border-radius: 25px;
  min-height: 180px;
  color: white !important;
  padding: 15px 20px 5px 30px;
  margin: 10px 0px;
  text-align: left;
}

.storage-card-title {
  font-size: 20px !important;
  font-weight: 600;
  margin-top: 40px;
}

.storage-bottom {
  background: url('/public/img/storage/storage-banner-2.png');
  background-repeat: no-repeat;
  background-size: cover;
}
