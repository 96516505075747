.App {
  text-align: center;
  font-family: 'poppins' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.no-border {
  border: 0;
  box-shadow: none;
  outline: none;
}

.no-border:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}

hr {
  border: none;
  height: 5px;
  color: #fff !important;
  /* old IE */
  background-color: #fff;
  /* Modern Browsers */
}

.pos-abs {
  position: absolute !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.primary-back {
  background-color: #0B0B0B !important
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fa-ring {
  border: 1px solid #E5B80B;
  border-radius: 30px;
  padding: 8px 10px;
  font-size: 12px !important;
}


.nav-back {
  background-color: #000000 !important;
  position: absolute;
  width: 100%;
  opacity: 0.9;
  z-index: 100;
}

.nav-item {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'poppins' !important;
}

@media screen and (min-width: 768px) {
  .nav-pd {
    padding: 0px 10%;
  }

  .brand-logo {
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  .nav-pd {
    padding: 0px 0px;
  }

  .brand-logo {
    width: 40px;
  }
}


.container-main {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px !important;
  margin-top: 20px !important;
  backdrop-filter: blur(10px) !important;
}

#mid-section {
  background: url('/public/img/home/home-back.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#mid-section-alt {
  background-color: #0B0A0A;
  background-repeat: no-repeat;
  background-size: cover;
}

.bottom-footer {
  background: url('/public/img/footer.png');
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 768px) {
  .section-title {
    padding: 40px 40px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 44px;
  }

  .mobile-only {
    display: none !important;
  }

  .footer-link {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #F4F3EE !important;
    font-family: 'Noto Sans', serif;
    text-align: left;
    padding-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .section-title {
    padding: 40px 40px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 24px;
  }

  .nav-link {
    text-align: left !important;
  }

  .desktop-only {
    display: none !important;
  }

  .footer-link {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #F4F3EE !important;
    font-family: 'Noto Sans', serif;
    text-align: left;
    padding-left: 10px;
  }
}


.bottom-left {
  position: absolute;
  bottom: 16px;
  left: 32px;
  color: #E5B80B;
  font-weight: 700;
  font-size: 20px;
}

.car-card {
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
}

@media screen and (min-width: 768px) {
  .text-home-primary {
    font-weight: 700;
    font-size: 36px;
  }

  .btn-primary-new {
    border: 1px solid #E5B80B !important;
    padding: 15px 25px;
    color: #E5B80B;
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .text-home-primary {
    font-weight: 700;
    font-size: 24px;
    padding: 0px 10px;
  }

  .btn-primary-new {
    border: 1px solid #E5B80B !important;
    padding: 8px 10px;
    color: #E5B80B;
    border-radius: 5px;
  }
}

.text-home-secondary {
  font-weight: 400;
  font-size: 18px;
}

.text-white {
  color: white !important;
  text-decoration: none !important;
}

.text-yellow {
  color: yellow !important;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary-new:hover {
  border: 1px solid #E5B80B !important;
  padding: 15px 20px;
  background-color: #E5B80B !important;
  border-radius: 5px;
  color: #000;
}

.footer {
  background-color: #151514 !important;
  position: relative !important;
  bottom: 0px !important;
}

.footer a {
  text-decoration: none !important;
}

.footer-title {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #F4F3EE !important;
  font-family: 'Noto Sans', sans-serif;
}



.nav-active {
  color: #E5B80B !important;
}

.accordion {
  border-bottom: 0px solid white !important;
}

.accordion-item {
  background-color: #0B0B0B !important;
  color: white;
  margin-top: 40px;
  border-bottom: 0px solid white !important;

}

.accordion-button {
  background-color: #0B0B0B !important;
  color: white !important;
  font-size: 22px;
  font-weight: 700;
}

.accordion-button:focus {
  border-bottom: 0.1px solid white !important;
}

.accordion-header {
  border-bottom: 0.1px solid white !important;
}

.accordion-button {
  border-bottom: 0.1px solid white !important;
}

.accordion-body {
  color: #A6A6A6 !important;
  text-align: left;
}

/* Override the default arrow color for the opened accordion */
.accordion-button[aria-expanded="true"]::before {
  color: white;
  /* Change this to the desired color */
}

/* Override the default arrow color for the closed accordion */
.accordion-button[aria-expanded="false"]::before {
  color: white;
  /* Change this to the desired color */
}

.social-icon {
  border: 1px solid #E5B80B;
  border-radius: 20px;
  padding: 5px;
  margin: 0px 10px;
  float: right;
  cursor: pointer;
}

.social-container {
  display: flex;
  justify-content: space-around
}

.form-select {
  background-color: transparent !important;
  color: white;
}

.form-control {
  border-color: #5A5A5A !important;
}

.form-select {
  border-color: #5A5A5A !important;
  color: #5A5A5A;
}

label {
  color: #5A5A5A;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5A5A5A !important;
  opacity: 1;
  /* Firefox */
}

.form-control:focus {
  outline: none;
}

.navbar-toggler {
  border: 0px;
}

.navbar-toggler:focus {
  outline: none;
  /* Remove the default focus outline */
  box-shadow: 0 0 0px rgba(0, 123, 255, 0.5);
}