/* STORAGE STARTS */
@media screen and (min-width: 768px) {

  .consignment-banner-1 {
    width: 100%;
    height: 100%;
  }

  .consignment-header {
    position: absolute;
    top: 30%;
    left: 10%;
    font-size: 44px !important;
    width: 40%;
    font-weight: 600;
    text-align: left;
  }

  .consignment-header-sub {
    font-size: 18px !important;
  }

  .consignment-form-container {
    background-color: rgba(20, 20, 21, 0.8) !important;
    width: 90% !important;
    margin: auto;
    color: #fff !important;
  }
}

@media screen and (max-width: 768px) {

  .consignment-banner-1 {
    width: 100%;
    height: 200px;
  }

  .consignment-header {
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 20px !important;
    width: 80%;
    font-weight: 700;
    text-align: left;
  }

  .consignment-header-sub {
    font-size: 12px;
  }

  .consignment-form-container {
    background-color: rgba(20, 20, 21, 0.8) !important;
    width: 90% !important;
    margin: auto;
    color: #fff !important;
  }
}



/* consignment */

.consignment-container {
  position: relative;
  text-align: center;
  color: white;
  width: 100%;
  min-height: 300px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .consignment-banner-img {
    width: 100%;
    height: auto;
    padding-top: 10%;
    object-fit: contain;
    transform: scale(1.2, 3.0);
    transition: transform 0.3s ease;
    transform-origin: right;
  }

  .consignment-banner-img-2 {
    width: 100%;
    height: auto;
    padding-top: 40%;
    object-fit: contain;
    transform: scale(2.0, 3.0);
    transform-origin: bottom;
  }

  .consignment-bottom-overlay {
    text-align: center;
    position: absolute;
    top: 5%;
    font-weight: 700;
    font-size: 10px;
    color: white !important;
  }

  .consignment-title {
    padding: 40px 20px;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 18px;
  }

  .consignment-sub {
    padding: 0px 10px;
    text-align: center;
    color: white;
    font-weight: 400;
    font-size: 12px;
  }
  .banner-btn-container {
    width: 50%;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {
  .consignment-banner-img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .consignment-banner-img-2 {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .consignment-bottom-overlay {
    position: absolute;
    bottom: 5%;
    width: 50%;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    color: white !important;
    padding: 0px 5%;
  }

  .consignment-title {
    padding: 20px 20px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 44px;
  }

  .consignment-sub {
    padding: 0px 20px;
    text-align: left;
    color: white;
    font-weight: 400;
    font-size: 18px;
  }

  .banner-btn-container {
    padding: 0px 5%;
  }
}

.form-control {
  height: 50px;
  background-color: inherit;
  color: white;
  border-radius: 0px;
  border-bottom: 1px solid white;
  ;
}

.form-control:focus {
  height: 50px;
  color: white;
  border-radius: 0px;
  background-color: inherit;
  border-bottom: 1px solid white;
  ;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.modal-terms-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}


.term-content {
  color: #fff;
  max-height: 80vh;
  overflow: scroll;
  padding: 20px 20%;
}