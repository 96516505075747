.options {
  margin: 0px 7%;
}

.news-title {
  text-align: left;
  background: url('/public/img/news/news-back.png');
}

@media screen and (max-width: 768px) {
  .news-image {
    height: 200px;
    width: 100%;
    object-fit: cover !important;
  }

  .badge {
    border-radius: 0px !important;
    background-color: #323232;
    padding: 8px 8px;
    cursor: pointer;
  }

  .badge-active {
    border-radius: 0px !important;
    background-color: #E5B80B;
    color: #323232;
  }

  .news-back {
    background-color: #0B0B0B;
    padding: 5% 2%
  }

  .news-page-head {
    font-size: 24px;
    color: white;
    font-weight: 700;
  }

  .news-page-subtitle {
    font-size: 12px;
    color: #A6A6A6;
    font-weight: 700;
  }

  .news-detail-banner {
    width: 100%;
    object-fit: cover !important;
  }

  .new-content {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    color: white;
  }

  .news-footer {
    background-color: #1C1C1C !important;
    padding: 5% 5%
  }
}

@media screen and (min-width: 768px) {
  .news-image {
    height: 300px;
    width: 100%;
    cursor: pointer;
    object-fit: cover !important;
  }

  .badge {
    border-radius: 0px !important;
    background-color: #323232;
    padding: 10px 10px;
    cursor: pointer;
  }

  .badge-active {
    border-radius: 0px !important;
    background-color: #E5B80B;
    color: #323232;
  }

  .news-back {
    background-color: #0B0B0B;
    padding: 5% 15% !important;
  }

  .news-page-head {
    font-size: 44px;
    color: white;
    font-weight: 700;
  }

  .news-page-subtitle {
    font-size: 16px;
    color: #A6A6A6;
    font-weight: 400;
  }

  .news-detail-banner {
    width: 100%;
    object-fit: cover !important;
  }

  .new-content {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: white;
  }

  .news-footer {
    background-color: #1C1C1C !important;
    padding: 5% 15%
  }
}
