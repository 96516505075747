@media screen and (min-width: 768px) {
  .home-banner-image {
    width: 100%;
    object-fit: fill;
    /* max-height: 90vh;
    min-height: 90vh; */
  }

  .homeVideo {
    display: block;
    max-height: 90vh;
    min-height: 90vh;
    object-fit: cover;
  }

  .car-card-image {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .home-banner-image-container {
    min-height: 300px;
    overflow: hidden;
  }

  .carousel-indicators {
    margin-left: 0px !important;
  }

  .home-banner-image {
    /* max-height: 500px; */
    width: 150%;
    max-height: 40vh;
    min-height: 40vh;
    object-fit: fill;
  }

  .homeVideo {
    display: block;
    max-height: 40vh;
    min-height: 40vh;
    object-fit: cover;
  }

  .car-card-image {
    width: 100vw !important;
  }
}

#homeCarousel {
  border-bottom: none !important;
}

#homeCarousel ol {
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  width: 20px;
}

#homeCarousel .active {
  background-color: yellow !important;
}

.video-container-carousal {
  position: relative;
}



/* Adjust the position of the video controls */
.homeVideo::-webkit-media-controls-panel {
  /* Example: Move the controls up by 20 pixels */
  transform: translateY(12px) !important;

}

/* For Firefox */
.homeVideo::-moz-media-controls-panel {
  /* Example: Move the controls up by 20 pixels */
  transform: translateY(12px) !important;
}
