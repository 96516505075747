#messageArea {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

@media screen and (max-width: 768px) {
  .mapLoc {
    width: 100%;
    height: 240px;
  }
}

@media screen and (min-width: 768px) {
  .mapLoc {
    width: 100%;
    height: 500px;
  }
}

.contact-top {
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 100px;
}