/* about */
@media screen and (max-width: 768px) {
  .about-bottom-overlay {
    position: absolute;
    bottom: 1%;
    font-weight: 700;
    font-size: 10px;
    text-align: left;
    color: white !important;
    padding: 0px 5%;
  }

  .about-car-container {
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    min-height: 300px;
  }

  .about-banner-img {
    width: 100%;
    height: auto;
    transform: scale(2.5, 2.5); 
    transform-origin: left;
  }

  .about-mid-container {
    position: relative;
    height: 400px;
  }

  .about-mid-image-1 {
    position: absolute;
    max-width: 50% !important;
    left: 40% !important;
  }

  .about-mid-image-2 {
    max-width: 95% !important;
    left: 0px !important;
    top: 30% !important;
  }

  .about-mid-bottom {
    padding: 0px 10%;
  }
}

@media screen and (min-width: 768px) {
  .about-car-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .about-bottom-overlay {
    position: absolute;
    bottom: 10%;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    color: white !important;
    padding: 0px 10%;
  }

  .about-banner-img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .about-mid-container {
    position: relative !important;
    height: 500px !important;
  }

  .about-mid-image-1 {
    max-width: 50% !important;
    left: 40% !important;
  }

  .about-mid-image-2 {
    max-width: 100% !important;
    left: 10px !important;
    top: 20% !important;
  }

  .about-mid-bottom {
    padding: 0px 20%;
  }
}