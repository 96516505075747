@media screen and (min-width: 768px) {
  .car-list-image {
    width: 100%;
    height: 400px;
    object-fit: cover !important;
  }
}

@media screen and (max-width: 768px) {
  .car-list-image {
    width: 100%;
    height: 200px;
    object-fit: cover !important;
  }
}



@media screen and (min-width: 768px) {
  .car-title {
    padding: 40px 0px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 20px;
  }

  .car-page-title {
    padding-top: 10%;
    /* padding-left: 6%; */
  }

  .car-sub-title {
    font-size: 18px;
    font-weight: 400;
    color: #A6A6A6;
  }

  .selectpicker {
    width: 80%
  }
}

@media screen and (max-width: 768px) {
  .car-title {
    padding: 40px 0px;
    text-align: left;
    color: white;
    font-weight: 700;
    font-size: 16px;
  }

  .car-page-title {
    padding-top: 15%;

    /* padding-left: 6%; */
  }

  .car-sub-title {
    font-size: 14px;
    font-weight: 400;
    color: #A6A6A6;
  }

  .selectpicker {
    width: 75%
  }
}

@media screen and (max-width: 768px) {
  .car-detail-img {
    width: 100%;
    height: 250px;
    padding: 10px;
    cursor: pointer;
    object-fit: cover !important;
  }

  .car-spec .field {
    font-weight: 700;
    font-size: 12px;
    text-align: left;
  }

  .car-spec .value {
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .car-detail-img {
    width: 100%;
    height: 500px;
    cursor: pointer;
    object-fit: cover !important;
  }

  .car-spec .field {
    font-weight: 700;
    font-size: 22px;
    text-align: left;
  }

  .car-spec .value {
    font-weight: 400;
    font-size: 22px;
    text-align: left;
  }

}

.car-spec .card-body {
  background-color: #1C1C1C !important;
}

.car-spec .card-footer {
  background-color: #1C1C1C !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top: 0.1px solid #3D3D3D;
}

.car-spec .card {
  border-radius: 20px;
}

.car-spec .card-header {
  background: url('/public/img/carDetails/car-spec-card-header.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
}

.video-container {
  position: relative;
  max-width: 800px;
  /* Set maximum width as needed */
  margin: 10px auto;
}

video {
  width: 100%;
  height: auto;
}

.modal {
  /* position: relative; */
  z-index: 999;
}

.modal-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  /* min-width: 80vw; */
  /* object-fit: fill !important; */
  /* min-height: 75vh; */
}

.close-button {
  position: absolute;
  top: 120px;
  right: 20px;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
  padding: 5px 25px;
  background-color: #E5B80B;
  border-radius: 5px;
  z-index: 9999;
}

.car-list-filter .form-select {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
}

.selectpicker:focus {
  outline: none;
  /* Remove the default focus outline */
  box-shadow: 0 0 0px rgba(0, 123, 255, 0.5);
}

textarea:focus {
  outline: none !important;
  /* Remove the default focus outline */
  box-shadow: 0 0 0px rgba(0, 123, 255, 0.5) !important;
}

.sidewise {
  margin: 10px;
  display: inline-block;
}